import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../components/Icon";
import Input from "../../components/Input";

const CustomerSignUpStep1 = ({ nextStep, onNameChange, onAcceptedTerms, onEmailChange, onPasswordChange, registrationData }) => {

	const [step, setStep] = useState(0);
	const [checked, setChecked] = useState(false);
	const [email, setEmail] = useState();
	const [name, setName] = useState();
	const [password, setPassword] = useState();

	const handleTerms = (e) => {
		const value = e.target.checked;
		setChecked(value);
		onAcceptedTerms(value, 'terms');
	}

	const handleName = (e) => {
		const value = e.target.value;
		setName(value);
		onNameChange(value, 'name');
	};

	const handleEmail = (e) => {
		const value = e.target.value;
		setEmail(value);
		onEmailChange(value, 'email');
	};

	const handlePassword = (e) => {
		const value = e.target.value;
		setPassword(value);
		onPasswordChange(value, 'password');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		nextStep();
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
					<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
						<div className="row g-3">
							<div className="col-6">
								<Input label="Name" onChange={handleName}/>
							</div>
							<div className="col-6">
								<Input label="Email" onChange={handleEmail}/>
							</div>
						</div>
					</div>
					<div className="mb-4 pb-sm-2">
						<Input label="Password" type={"password"} onChange={handlePassword} />
					</div>
					<div className="mb-4 pb-sm-1">
						<label className="form-check form--check">
							<input
								type="checkbox"
								className="form-check-input"
								checked={checked}
								onChange={(e) => handleTerms(e)}
							/>
							<span
								className="form-check-label"
								// style={{ maxWidth: "269px" }}
							>
								I’VE READ AND AGREE WITH{" "}
								<Link className="text-base base-2">
									TERMS OF SERVICE
								</Link>{" "}
								AND OUR{" "}
								<Link className="text-base base-2">PRIVACY POLICY</Link>
							</span>
						</label>
					</div>

					<div className="mb-4">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
				<div className="mb-4">
					<div className="or">Or sign up with</div>
				</div>
				<div className="d-flex gap-4 mb-4 pb-sm-3 social-wrapper">
					<button className="no-gutter">
						<FacebookIcon />
					</button>
					<button className="no-gutter">
						<GoogleIcon />
					</button>
					<button className="no-gutter">
						<AppleIcon />
					</button>
				</div>
		</>
	);
};

export default CustomerSignUpStep1;
