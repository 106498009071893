import React, {useEffect, useState} from "react";
import { CalendarIcon4, ProIcon } from "./Icon";

const StandardSignalCard4 = ({props, postMessage}) => {

	const [initialized, setInitialized] = useState(false)

	useEffect(() => {
		setInitialized(true)
	}, []);

	useEffect(() => {
		postMessage()
	},[initialized])

	return (
		<>
			<div id='signal_view' lassName="signal-widget extra-signal-widget extra-signal-widget-4">
				<div className="pro-title">
					<span>PRO</span>
					<ProIcon />
				</div>
				<div className="signal-widget-wrapper">
					<div className="d-flex flex-wrap align-items-center justify-content-center pt-3 gap-1 pb-3 timestamp">
						<CalendarIcon4 />
						<span
							className="font-semibold"
							style={{ color: "#212128", opacity: "0.5" }}
						>
							12 November 2022 12:57
						</span>
					</div>
					<h5 className="text-center d-flex align-items-center justify-content-center gap-2 mb-3">
						<span>+20.45%</span> <img src="/flags/check.svg" alt="" />{" "}
					</h5>
					<div className="d-flex justify-content-center currencies-tab">
						<button className="no-gutter">EUR</button>
						<button className="no-gutter active">USD</button>
					</div>
					<div className="pt-4 d-flex justify-content-between info-texts">
						<div className="me-auto">
							<div className="mb-2">
								<h6 className="s-title">47523</h6>
								<small>Alert ID</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">35821</h6>
								<small>Signal ID</small>{" "}
								<button
									className="d-inline-block btn btn-secondary"
									type="button"
								>
									Copy
								</button>
							</div>
						</div>
						<div className="mx-auto">
							<div className="mb-2">
								<h6 className="s-title">1.19</h6>
								<small>TP</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">1.181</h6>
								<small>SL</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StandardSignalCard4;
