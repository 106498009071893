import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { ImageUpload } from "../../components/imageUpload";
import CustomerAuthLayout from "./components/CustomerAuthLayout";
import CustomerSignUpStep2 from "./CustomerSignUpStep2";
import CustomerSignUpStep3 from "./CustomerSignUpStep3";
import CustomerSignUpStep4 from "./CustomerSignUpStep4";
import CustomerSignUpStep1 from "./CustomerSignUpStep1";
import { CustomerRegistrationHelper } from "../../util/CustomerRegistrationHelper";
import { APIBaseURL, CurrentCompany, Subdomain } from "../../util/DomainHelper";
import {toast} from "react-toastify";

const CustomerSignUpStepper = ({ onTitleChange }) => {

	const [step, setStep] = useState(0);
	const [title, setTitle] = useState();
	const [subtitle, setSubtitle] = useState();
	const [text, setText] = useState();
	const [percentage, setPercentage] = useState('0');

	const navigate = useNavigate();

	const navigateNextStep = async () => {
		if (step !== 3) {
			if (step === 0) {
				const fieldsValidated = await verifyEmailAddressAndPassword();
				if (fieldsValidated) {
					setStep(step + 1);
				}
			} else {
				setStep(step + 1);
			}
		} else {
			createUserAccount();
			// navigate('/success/23n09s0d9g034m')
		};

		configureTextForStep();
	};

	const configureTextForStep = () => {
		if (step === 0) {
			setTitle("Sign Up");
			setSubtitle("Profile Details");
			setText("Join an online community of educators and start educating.");
			setPercentage('33');
		} else if (step === 1) {
			setTitle("Sign Up");
			setSubtitle("Subscription");
			setText("Join an online community of educators and start educating.");
			setPercentage('66');
		} else if (step === 2) {
			setTitle("Sign Up");
			setSubtitle("Payment");
			setText("Join an online community of educators and start educating.");
			setPercentage('90');
		} else {

		}
	}

	const verifyEmailAddressAndPassword = async () => {
		// Check if email doesn't exist, password is sufficient & terms are accepted
		const registrationData = CustomerRegistrationHelper.getRegistrationData();
		const nameValid = validateFullName(registrationData ? registrationData.name : '');
		if (!nameValid) {
			// console.log('First and last name required');
			toast.warn('First and last name required');
			return false
		}
		const emailValid = validateEmail(registrationData ? registrationData.email : '');
		if (!emailValid) {
			// console.log('Please enter a valid email address');
			toast.warn('Please enter a valid email address');
			return false;
		}
		const passwordValid = validatePassword(registrationData ? registrationData.password : '');
		if (!passwordValid) {
			// console.log("Password needs to be at least 8 characters long, containing 1 uppercase letter and a special character.");
			toast.warn('Password needs to be at least 8 characters long, containing 1 uppercase letter and a special character.')
			return false;
		}
		const acceptedTerms = registrationData.terms ?? false;
		if (!acceptedTerms) {
			// console.log("You must review and agree to the terms of service and privacy policy");
			toast.warn('You must review and agree to the terms of service and privacy policy');
			return false;
		}
		// FINAL VALIDATION STEP
		const emailStatus = await checkEmailAddress(registrationData ? registrationData.email : '');
		if (emailStatus === 'in-use') {
			// console.log("Looks like this email address is already in use");
			toast.warn('Looks like this email address is already in use');
			return false;
		} else {
			return true
		}
	};

	const handleRegistrationData = (e, field) => {
		const value = e;
		CustomerRegistrationHelper.saveRegistrationData(value, field);
	};

	async function checkEmailAddress(email) {
		try {
			const company = await CurrentCompany();
			console.log(company);
			const url = APIBaseURL() + `auth/verify-email`;
			const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                },
				body: JSON.stringify({companyId: company._id, email: (email ?? '').toLowerCase()})
            });
            const responseJSON = await response.json();
            const data = responseJSON.data ?? true;
			const value = (data === true) ? 'in-use' : 'available';
			console.log(value);
			return value;
		} catch(error) {
			console.log(error);
			return 'in-use';
		}
	};

	function validateFullName(name) {
		const nameParts = (name ?? '').split(' ');
		return nameParts.length >= 2;
	}

	function validateEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email ?? '');
	}

	function validatePassword(password) {
		const uppercaseRegex = /[A-Z]/;
		const numberOrSpecialCharRegex = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
		const hasUppercase = uppercaseRegex.test(password ?? '');
		const hasNumberOrSpecialChar = numberOrSpecialCharRegex.test(password ?? '');
		const isLengthValid = (password ?? '').length >= 8;
		return hasUppercase && hasNumberOrSpecialChar && isLengthValid;
	};

	const createUserAccount = () => {
		navigate('/success/23n09s0d9g034m');
	};

	useEffect(() => {
		localStorage.removeItem('registrationData');
	},[]);

	useEffect(() => {
		const newTitle = "Sign Up";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
			<CustomerAuthLayout
				title={title ?? 'Sign Up'}
				subtitle={subtitle ?? 'Profile Details'}
				text={text ?? 'Join an online community of educators and start educating.'}
			>
				{step !== 0 && (
					<div className="mb-4">
						<div className="progress __progress mt-4 base-2">
							<div
								className="progress-bar"
								role="progressbar"
								style={{ width: `${percentage}%` }}
							></div>
						</div>
					</div>
				)}

				{step === 1 && (
					<CustomerSignUpStep1
					nextStep={navigateNextStep}
					onNameChange={handleRegistrationData}
					onAcceptedTerms={handleRegistrationData}
					onEmailChange={handleRegistrationData}
					onPasswordChange={handleRegistrationData}/>
				)}
				{step === 0 && (
					<CustomerSignUpStep2
					nextStep={navigateNextStep}
					onUsernameChange={handleRegistrationData}
					onProfileImageChange={handleRegistrationData}
					onPhoneNumberChange={handleRegistrationData} />
				)}
				{step === 2 && (
					<CustomerSignUpStep3
					nextStep={navigateNextStep}
					onPackageSelected={handleRegistrationData} />
				)}
				{step === 3 && (
					<CustomerSignUpStep4
					nextStep={navigateNextStep}
					onCardNumberChange={handleRegistrationData}
					onCvcChange={handleRegistrationData}
					onExpChange={handleRegistrationData}
					onZipCodeChange={handleRegistrationData} />
				)}
			</CustomerAuthLayout>
		</>
	);
};

export default CustomerSignUpStepper;
