import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import gallery from "../assets/img/icons/gallery.png";
import smile from "../assets/img/icons/smile.png";
import video from "../assets/img/icons/video.png";
import Input from "./Input";
import SignalCard from "./SignalCard";
import {CurrentCompany} from "../util/CompanyHelper";
import NetworkingProvider from "../providers/NetworkingProvider";
import UserProvider from "../providers/UserProvider";

const SignalPost = (props) => {

	let TestTradeIdea = {
		trade_idea: {
			symbol: 'Choose Pair',
			sender: '',
			visibility: '',
			configuration: '',
			status: '',
			execution_category: "Sell",
			execution_type: 'Swing',
			timestamp: "15 April 2013 8:32 AM",
			entries: [
				{ value: 'Enter One' },
			],
			values_1: [
				{ value: 'Enter One' },
			],
			values_2: [
				{ value:'Enter One' },
			],
		}
	}

	const [configuredTradeIdea, setConfiguredTradeIdea] = useState(TestTradeIdea);
	const [count, setCount] = useState(0);
	const [configurationOptions, setConfigurationOptions] = useState([]);
	const [statusOptions, setStatusOptions] = useState([]);

	const [initialized, setInitialized] = useState(false);

	const incrementCount = () => {
		setCount(count + 1);
	};

	function handleVisibility(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.visibility = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleExecutionCategory(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.execution_category = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleExecutionStyle(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.execution_type = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleEntryInputs(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.entries = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleValue1Inputs(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.values_1 = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleValue2Inputs(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.values_2 = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}
	//
	function handleSymbolSelection(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.symbol = e.toUpperCase();
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleConfiguration(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.configuration = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	function handleStatus(e) {
		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.status = e;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount()
	}

	async function handleTradeIdeaSubmission() {
		// TODO: Perform Check Form Validation

		const currentUser = UserProvider.getCurrentUser();
		if (!currentUser) {
			return;
		}

		let updatedTradeIdea = configuredTradeIdea;
		updatedTradeIdea.trade_idea.timestamp = new Date().getTime();
		updatedTradeIdea.trade_idea.sender = currentUser._id;
		setConfiguredTradeIdea(updatedTradeIdea)
		incrementCount();

		const currentCompany = await CurrentCompany(false);
		if (!currentCompany) {
			return;
		}
		try {
			const dataResponse = await NetworkingProvider.fetchData(`trade-ideas/create/${currentCompany._id}`, 'POST', configuredTradeIdea);
			const data = dataResponse.data;
			const message = dataResponse.message;
			if (data) {
				props.onSuccess();
				return props.onHide();
			}
			if (message) {
				return props.onError(message)
			}
		} catch (e) {
			props.onError('An unexpected error occurred. Please try again.');
			return console.log(e)
		}
		console.log(configuredTradeIdea)
	}

	async function initializePage() {
		try {
			const currentCompany = await CurrentCompany(false);
			if (!currentCompany) {
				return;
			}
			const dataResponse = await NetworkingProvider.fetchData(`trade-ideas/setup/${currentCompany._id}`, 'POST');
			const data = dataResponse.data;
			const message = dataResponse.message;
			if (data) {
				setStatusOptions(data.statuses ?? [])
				setConfigurationOptions(data.configurations ?? [])
				return setInitialized(true);
			}
			if (message) {
				return props.onError(message)
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		initializePage()
	},[configuredTradeIdea])

	return (
		<>
			<Modal
				{...props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="signalModal"
			>
				{ initialized && (
					<div className="row row-gap-4">
						<div className="col-xl-12">
							<h4 className="h4">New Trade Idea</h4>
							{/*<div className="d-flex align-items-center gap-4">*/}
							{/*	<Input placeholder="Buy" height={"47px"} />*/}
							{/*	<Input placeholder="Sell" height={"47px"} />*/}
							{/*</div>*/}
						</div>
						<div className="col-xl-8">
							<div className="row row-gap-4">
								{/*<div className="col-xl-4"></div>*/}
								<div className="col-xl-3">
									<div className="text-18">Alert ID</div>
									<Input className="id-input" value="47523" height={"47px"}/>
								</div>
								<div className="col-xl-4">
									<div className="text-18">Platform Visibility</div>
									<Input className="id-input"
										   onChange={handleVisibility}
										   options={[
											   {
												   name: "Select One",
												   value: "",
											   },
											   {
												   name: "All Members",
												   value: "global",
											   },
											   {
												   name: "Pro Members Only",
												   value: "pro",
											   },
											   {
												   name: "Send Privately",
												   value: "private",
											   },
										   ]}
										   height={"47px"}
									/>
								</div>
								<div className="col-xl-5">
									<div className="text-18">Status</div>
									<Input className="id-input"
										   onChange={handleStatus}
										   options={[{name: 'Select One', value: ''}, ...statusOptions]}
										   height={"47px"}
									/>
								</div>
								<div className="col-xl-12">
									<div className="text-18">Signal Configuration</div>
									<Input
										className="id-input"
										onChange={handleConfiguration}
										options={[{name: 'Select One', value: ''}, ...configurationOptions]}
										height={"47px"}
									/>
								</div>
								<div className="col-xl-12">
									<div className="text-18">Choose Pair</div>
									<Input
										onChange={handleSymbolSelection}
										options={[
											{
												name: "Select Pair",
												value: "",
											},
											{
												name: "EURUSD",
												value: "eurusd",
											},
											{
												name: "USDJPY",
												value: "usdjpy",
											},
										]}
										height={"47px"}
									/>

								</div>
								<div className="col-xl-6">
									<div className="text-18">Trade Type</div>
									<Input
										onChange={handleExecutionCategory}
										options={[
											{
												name: "Select Trade Type",
												value: "",
											},
											{
												name: "Buy",
												value: "buy",
											},
											{
												name: "Sell",
												value: "sell",
											},
											{
												name: "Sell Stop",
												value: "sell-stop",
											},
											{
												name: "Buy Stop",
												value: "buy-stop",
											},
										]}
										height={"47px"}
									/>
								</div>

								<div className="col-xl-6">
									<div className="text-18">Trading Style</div>
									<Input
										onChange={handleExecutionStyle}
										placeholder=""
										options={[
											{
												name: "Select Trade Style",
												value: "",
											},
											{
												name: "Swing",
												value: "swing",
											},
											{
												name: "Scalp",
												value: "scalp",
											},
											{
												name: "Custom",
												value: "custom",
											},
										]}
										height={"47px"}
									/>
								</div>
								<div className="col-xl-6">
									<div className="text-18">Entries</div>
									<CreatableSelect
										onChange={handleEntryInputs}
										isMulti
										name="colors"
										className="basic-multi-select"
										classNamePrefix="__select"
										placeholder=""
									/>
								</div>
								<div className="col-xl-6">
									<div className="text-18">Risk/Reward</div>
									<Input placeholder="" height={"47px"} endText={"%"}/>
								</div>

								<div className="col-xl-6">
									<div className="text-18">Take Profits</div>
									<CreatableSelect
										onChange={handleValue1Inputs}
										isMulti
										name="colors"
										className="basic-multi-select"
										classNamePrefix="__select"
										placeholder=""
									/>
								</div>
								<div className="col-xl-6">
									<div className="text-18">Stop Losses</div>
									<CreatableSelect
										onChange={handleValue2Inputs}
										isMulti
										name="colors"
										className="basic-multi-select"
										classNamePrefix="__select"
										placeholder=""
									/>
								</div>
							</div>
						</div>


						<div className="col-xl-4">
							<SignalCard props={configuredTradeIdea}/>
						</div>
						<div className="col-xl-12">
							<hr className="border-footer-modal"/>
							<div className="d-flex align-items-center">
								<div className="modal-icons d-flex align-items-center gap-3">
									<Link to="">
										<img src={smile} alt="smile"/>{" "}
									</Link>
									<Link to="">
										<img src={gallery} alt="smile"/>{" "}
									</Link>
									<Link to="">
										<img src={video} alt="smile"/>{" "}
									</Link>
								</div>
								<button
									onClick={() => handleTradeIdeaSubmission()}
									type="submit"
									className="ms-auto cmn-btn text-16"
								>
									Send
								</button>
							</div>
						</div>
					</div>
				)}

			</Modal>
		</>
	);
};

export default SignalPost;
