import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import NetworkingProvider from "../../providers/NetworkingProvider";
import SignalCard from "../../components/SignalCard";
import StandardSignalCard from "../../components/StandardSignalCard";
import StandardSignalCard2 from "../../components/StandardSignalCard2";
import StandardSignalCard3 from "../../components/StandardSignalCard3";
import StandardSignalCard4 from "../../components/StandardSignalCard4";

const defaultValues = [
    {
        name: "Standard",
        value: 0,
    },
    {
        name: "Style 1",
        value: 1,
    },
    {
        name: "Style 2",
        value: 2,
    },
    {
        name: "Style 3",
        value: 3,
    },
    {
        name: "Style 4",
        value: 4,
    },
]

const TradeIdeaView = () => {

    const params = useParams();

    const signalFrame = useRef(null);

    const [tradeIdea, setTradeIdea] = useState(null);
    const [tradeIdeaConfigurations, setTradeIdeaConfigurations] = useState(defaultValues);
    const [initialized, setInitialized] = useState(false)

    async function fetchTradeConfigurations() {
        // console.log(params)
        try {
            const dataResponse = await NetworkingProvider.fetchData('trade-ideas/configurations', 'GET');
            // console.log(dataResponse);
            const data = dataResponse.data;
            if (data) {
                setTradeIdeaConfigurations(data)
            }
        } catch (e) {

        }
    }

    async function fetchTradeIdea() {
        try {
            const dataResponse = await NetworkingProvider.fetchData(`trade-ideas/view/${params.tradeIdeaId}`, 'GET');
            // console.log(dataResponse);
            const data = dataResponse.data;
            if (data) {
                setTradeIdea(data)
                // setInitialized(true)
            }
        } catch (e) {

        }
    }

    async function initializePage() {
        await fetchTradeConfigurations();
        await fetchTradeIdea();
        setInitialized(true)
    }

    function getConfigurationId() {
        if (tradeIdea && tradeIdeaConfigurations.length > 0) {
            const tradeIdeaData = tradeIdea.tradeIdea
            // console.log('tradeIdeaData', tradeIdeaData)
            const signalConfigurationData = tradeIdeaData.configuration
            // console.log('signalConfigurationData', signalConfigurationData)
            // console.log('tradeIdeaConfigurations', tradeIdeaConfigurations)
            const targetSignalConfiguration = (tradeIdeaConfigurations ?? []).findIndex((config) => config._id === signalConfigurationData);
            // console.log('targetSignalConfiguration', targetSignalConfiguration)
            return targetSignalConfiguration ?? 0
        } else {
            return 0
        }
    }

    function sendPostMessage() {
        if (signalFrame) {
            if (signalFrame.current) {
                const clientHeight = signalFrame.current.clientHeight
                if (clientHeight) {
                    if (window.parent) {
                        window.parent.postMessage(clientHeight, '*');
                    }
                }
            }
        }
    }


    useEffect(() => {
        initializePage()
    },[])

    return(
        <div ref={signalFrame} style={{backgroundColor: '#1E1E27'}}>
            { initialized && tradeIdeaConfigurations && tradeIdea && (
                <>
                    { getConfigurationId() === 0 && (
                        <SignalCard props={tradeIdea.tradeIdea} postMessage={sendPostMessage}/>
                    )}
                    { getConfigurationId() === 1 && (
                        <StandardSignalCard props={tradeIdea.tradeIdea} postMessage={sendPostMessage}/>
                    )}
                    { getConfigurationId() === 2 && (
                        <StandardSignalCard2 props={tradeIdea.tradeIdea} postMessage={sendPostMessage}/>
                    )}
                    { getConfigurationId() === 3 && (
                        <StandardSignalCard3 props={tradeIdea.tradeIdea} postMessage={sendPostMessage}/>
                    )}
                    { getConfigurationId() === 4 && (
                        <StandardSignalCard4 props={tradeIdea.tradeIdea} postMessage={sendPostMessage}/>
                    )}
                </>
            )}
        </div>
    )


}

export default TradeIdeaView
