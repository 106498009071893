import React, {useEffect, useState} from "react";

const StandardSignalCard2 = ({ props, postMessage}) => {

	const [initialized, setInitialized] = useState(false)

	useEffect(() => {
		setInitialized(true)
	}, []);

	useEffect(() => {
		postMessage()
	},[initialized])

	return (
		<>
			<div className="signal-widget extra-signal-widget extra-signal-widget-2">
				<div className="signal-widget-wrapper">
					<div className="text-center top-image-area py-0 mb-19">
						<div className="d-flex flex-wrap gap-2 note-info-items align-items-center justify-content-center">
							<div className="name-area">
								<h4 className="name">USD</h4>
								<div className="subname">United States Dollar</div>
							</div>
							<img
								src={"/flags/arrow.png"}
								width={22}
								className="mx-3"
								alt=""
							/>
							<div className="name-area">
								<h4 className="name">EUR</h4>
								<div className="subname">European Union</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-wrap align-items-center gap-1 time-stamp">
						<strong className="text-white font-semibold w-100px">
							Return:
						</strong>
						<span
							className="font-semibold"
							style={{ color: "rgba(255, 255, 255, 0.5)" }}
						>
							+20.45%
						</span>
					</div>
					<div className="d-flex flex-wrap align-items-center gap-1 time-stamp mt-2">
						<strong className="text-white font-semibold w-100px">
							Time Stamp:
						</strong>
						<span
							className="font-semibold"
							style={{ color: "rgba(255, 255, 255, 0.5)" }}
						>
							12 November 2022 12:57
						</span>
					</div>
					<div className="py-30">
						<img src={"/flags/chart.png"} className="w-100" alt="" />
					</div>
					<div className="border-bottom-2" />
					<div className="pt-4 d-flex justify-content-between info-texts">
						<div className="me-auto">
							<div className="mb-2">
								<h6 className="s-title">47523</h6>
								<small>Alert ID</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">35821</h6>
								<small>Signal ID</small>{" "}
								<button
									className="d-inline-block btn btn-secondary"
									type="button"
								>
									Copy
								</button>
							</div>
						</div>
						<div className="mx-auto">
							<div className="mb-2">
								<h6 className="s-title">1.19</h6>
								<small>TP</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">1.181</h6>
								<small>SL</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StandardSignalCard2;
