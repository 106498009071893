import React, {useEffect, useRef, useState} from "react";

import dolarbag from "../assets/img/dolar-bag.png";
import verified from "../assets/img/verified.png";

import arrow from "../assets/img/flag/arrow.png";
import aus from "../assets/img/flag/aus.png";
import usa from "../assets/img/flag/usa.png";
import { UpperArrowChartIcon } from "./Icon";
import {getCompanySignalConfiguration} from "../util/CompanyHelper";
import StandardSignalCard2 from "./StandardSignalCard2";
import StandardSignalCard3 from "./StandardSignalCard3";
import StandardSignalCard4 from "./StandardSignalCard4";
import StandardSignalCard from "./StandardSignalCard";

const SignalCard = ({ props, postMessage }) => {

	const [currentCompanySignalConfiguration, setCurrentCompanySignalConfiguration] = React.useState();
	const [initialized, setInitialized] = useState(false)

	function copyValue(value) {
		console.log(`Copying: ${value}`)
	}

	useEffect(() => {
		const currentConfig = getCompanySignalConfiguration()
		if (currentConfig) {
			const goldenElement = parseInt(currentConfig.element)
			setCurrentCompanySignalConfiguration(goldenElement)
		} else {
			setCurrentCompanySignalConfiguration(0)
		}
		setInitialized(true)
	}, [])

	useEffect(() => {
		postMessage()
	},[initialized])

	return (
		<>
			{ currentCompanySignalConfiguration !== undefined ? (
				<>
					{ currentCompanySignalConfiguration === 0 && (
						<div id={'signal_view'}  className="signal-widget">
							<div className="text-center dolar-box">
								<img src={dolarbag} alt=""/>
							</div>
							<div className="signal-widget-wrapper">
								<div className="text-center">
									<h5 className="maintxt">🚨 New Trade</h5>
									<h6 className="subtxt">ALERT ID: 473463</h6>
									<img src={verified} alt=""/>
									<div
										className="d-flex flex-wrap gap-2 note-info-items align-items-center justify-content-center mt-3">
										<button className="no-gutter" type="button">
											<img width={22} src={usa} alt=""/>
										</button>
										<img src={arrow} width={12} alt=""/>
										<button className="no-gutter" type="button">
											<img width={22} src={aus} alt=""/>
										</button>
									</div>
								</div>
								<div className="usd-long mt-3">
									<div>
										<h6 className="name">{props ? (props.trade_idea ? props.trade_idea.symbol : props ? props.symbol : '') : ''}</h6>
										United States Dollar
									</div>
									<div className="long-badge">
										<span>{props ? (props.trade_idea ? (props.trade_idea.execution_category).toUpperCase() : props ? (props.execution_category).toUpperCase() : '') : ''}</span>{" "}
										<span>
								<UpperArrowChartIcon/>
							</span>
									</div>
								</div>
								<div
									className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-1 time-stamp border-bottom-2 pb-3">
									<strong className="text-white font-semibold">
										Timestamp:
									</strong>
									<span
										style={{color: "rgba(255, 255, 255, 0.5)"}}> {props ? (props.trade_idea ? props.trade_idea.timestamp : props ? props.timestamp : '') : ''} </span>
								</div>
								<div className="pt-4 d-flex justify-content-between info-texts">
									<div className="me-auto ">
										<div className="mb-2">
											{(props ? (props.trade_idea ? (props.trade_idea.entries ? props.trade_idea.entries : []) : props ? (props.entries ? props.entries : []) : []) : []).map((entry, index) => {
												return (
													<div className="mb-2" key={index}>
														<h6 className="s-title">{props ? entry.value : ''}</h6>
														<small>Entry {index + 1}</small>
														<button style={{marginLeft: '10px'}}
																onClick={() => copyValue(entry.value)}
																className="d-inline-block btn btn-secondary"
																type="button"
														>
															Copy
														</button>
													</div>
												)
											})}

										</div>

										<div className="mb-2">
											<h6 className="s-title">{props ? (props.trade_idea ? props.trade_idea.execution_type : props ? props.execution_type : '') : ''}</h6>
											<small>Style</small>
										</div>

									</div>
									<div className="story-divider mx-auto h-auto"></div>
									<div className="mx-auto">
										{(props ? (props.trade_idea ? (props.trade_idea.values_1 ? props.trade_idea.values_1 : []) : props && (props.values_1 ? props.values_1 : [])) : []).map((entry, index) => {
											return (
												<div className="mb-2" key={index}>
													<h6 className="s-title">{entry.value}</h6>
													<small>TP {index + 1}</small>{" "}
													<button style={{marginLeft: '10px'}} onClick={() => copyValue(entry.value)}
															className="d-inline-block btn btn-secondary"
															type="button"
													>
														Copy
													</button>
												</div>
											)
										})}
										<div className="border-bottom-2 mb-3 pb-2"></div>
										{(props ? (props.trade_idea ? (props.trade_idea.values_2 ? props.trade_idea.values_2 : []) : props ? (props.values_2 ? props.values_2 : []) : []) : []).map((entry, index) => {
											return (
												<div className="mb-2" key={index}>
													<h6 className="s-title">{entry.value}</h6>
													<small>SL {index + 1}</small>{" "}
													<button style={{marginLeft: '10px'}} onClick={() => copyValue(entry.value)}
															className="d-inline-block btn btn-secondary"
															type="button"
													>Copy
													</button>
												</div>
											)
										})}
										{/* <div className="mb-2">
								<h6 className="s-title">{props ? (props.values_2 ? (props.values_2.length > 0 ? (props.values_2[0].value) : '') : '') : ''}</h6>
								<small>SL</small>
							</div> */}
									</div>
								</div>
							</div>
						</div>
					)}
					{ currentCompanySignalConfiguration === 1 && (
						<StandardSignalCard props={props}/>
					)}
					{ currentCompanySignalConfiguration === 2 && (
						<StandardSignalCard2 props={props}/>
					)}
					{ currentCompanySignalConfiguration === 3 && (
						<StandardSignalCard3 props={props}/>
					)}
					{ currentCompanySignalConfiguration === 4 && (
						<StandardSignalCard4 props={props}/>
					)}
				</>
				) : null}
		</>
	);
};

export default SignalCard;
