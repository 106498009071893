import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { ImageUpload } from "../../components/imageUpload";
import CustomerAuthLayout from "./components/CustomerAuthLayout";

const CustomerSignUpStep2 = ({ nextStep, onUsernameChange, onProfileImageChange, onPhoneNumberChange, registrationData }) => {
	
	const [phone, setPhone] = useState("");
	const [files, setFiles] = useState([]);

	const handleSubmit = (e) => {
		e.preventDefault();
		nextStep();
	};

	const handleProfileImage = (e) => {
		setFiles(e);
		onProfileImageChange(e, 'profileImage')
	};

	const handlePhone = (event) => {
		const value = event.target.value.replace(/\D/g, "");
		setPhone(value);
		onPhoneNumberChange(value, 'phone');
	};

	const handleUsername = (e) => {
		const value = e.target.value;
		onUsernameChange(value, 'username')
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
					<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
						<div className="row g-3">
							<div className="col-12">
								<ImageUpload files={files} setFiles={handleProfileImage} />
								<br className="d-none d-md-block" />
								<br />
							</div>
							<div className="col-6">
								<Input label="Username*" onChange={handleUsername}/>
							</div>
							<div className="col-6">
								<Input
									label="Phone Number"
									value={phone}
									onChange={handlePhone}
								/>
							</div>
						</div>
						<br className="d-none d-md-block" />
						<br className="d-none d-md-block" />
						<br />
					</div>
					<div>
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
		</>
	);
};

export default CustomerSignUpStep2;
