import React, {useEffect, useState} from "react";

import { UpperArrowChartIcon } from "./Icon";

const StandardSignalCard = ({ props, postMessage}) => {

	const [initialized, setInitialized] = useState(false)

	useEffect(() => {
		setInitialized(true)
	}, []);

	useEffect(() => {
		postMessage()
	},[initialized])

	return (
		<>
			<div className="signal-widget extra-signal-widget">
				<div className="text-center top-image-area">
					<div className="d-flex flex-wrap gap-2 note-info-items align-items-center justify-content-center">
						<button className="no-gutter" type="button">
							<img width={41} src={"/flags/us.png"} alt="" />
						</button>
						<img
							src={"/flags/arrow.png"}
							width={22}
							className="mx-3"
							alt=""
						/>
						<button className="no-gutter" type="button">
							<img width={41} src={"/flags/uk.png"} alt="" />
						</button>
					</div>
				</div>
				<div className="signal-widget-wrapper">
					<h5 className="text-center d-flex align-items-center justify-content-center gap-2">
						<span>+20.45%</span> <img src="/flags/check.svg" alt="" />{" "}
					</h5>
					<div className="usd-long mt-3">
						<div>
							<h6 className="name">USD</h6>
							United States Dollar
						</div>
						<div className="long-badge">
							<span>LONG</span>{" "}
							<span>
								<UpperArrowChartIcon />
							</span>
						</div>
					</div>
					<div className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-1 time-stamp border-bottom-2 pb-2">
						<strong className="text-white font-semibold">
							Time Stamp:
						</strong>
						<span style={{ color: "rgba(255, 255, 255, 0.5)" }}>
							12 November 2022 12:57
						</span>
					</div>
					<div className="pt-4 d-flex justify-content-between info-texts">
						<div className="me-auto">
							<div className="mb-2">
								<h6 className="s-title">47523</h6>
								<small>Alert ID</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">35821</h6>
								<small>Signal ID</small>{" "}
								<button
									className="d-inline-block btn btn-secondary"
									type="button"
								>
									Copy
								</button>
							</div>
						</div>
						<div className="mx-auto">
							<div className="mb-2">
								<h6 className="s-title">1.19</h6>
								<small>TP</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">1.181</h6>
								<small>SL</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StandardSignalCard;
