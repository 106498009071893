import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomerAuthLayout from "./components/CustomerAuthLayout";

import img1 from "../../assets/img/plan/plan-bg-1.png";
import img2 from "../../assets/img/plan/plan-bg-2.png";
import img3 from "../../assets/img/plan/plan-bg-3.png";

const CustomerSignUpStep3 = ({ nextStep, onPackageSelected, registrationData }) => {

	const [selected, setSelected] = useState("")

	const handleSubmit = (e) => {
		e.preventDefault();
		nextStep();
	};

	const handlePackageSelected = (e) => {
		setSelected(e)
		onPackageSelected(e, 'package')
	};

	// title="Sign Up"
	// subtitle="Subscription"
	// text="Join an online community of educators and start educating."
	// 60

	return (
		<>
			<form onSubmit={handleSubmit}>
					<label className="form-label d-block mb-5 form--label">
						Subscriptions
					</label>
					<div className="row g-4">
						{data.map((item, index) => (
							<div className="col-12" key={index}>
								<div
									className={`card card-2 ${
										selected === item.type ? "active" : ""
									}`}
									style={{
										background: `url(${item.bg}) no-repeat center center / cover`,
									}}
								>
									<div className="card-body">
										<div>
											<div className="card-title">
												Tradessly{" "}
												<span className="badge">{item.type}</span>
											</div>
											<div className="card-text">{item.subtext}</div>
										</div>
										<div>
											<button
												type="button"
												className={`plan-btn ${
													selected === item.type ? "active" : ""
												}`}
												onClick={() => handlePackageSelected(item.type)}
											>
												{selected === item.type
													? "Selected"
													: "Select"}
											</button>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="mt-4 mt-sm-5">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
		</>
	);
};

const data = [
	{
		type: "Trial",
		subtext: "First Week Free",
		bg: img1,
	},
	{
		type: "PRO",
		subtext: "$99.99 / Year",
		bg: img2,
	},
	{
		type: "Ultimate",
		subtext: "$999.99 One-Time",
		bg: img3,
	},
];

export default CustomerSignUpStep3;
