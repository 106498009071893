import React, { useState } from "react";

import Input from "../../components/Input";

const CustomerSignUpStep4 = ({ nextStep, onCardNumberChange, onCvcChange, onExpChange, onZipCodeChange, registrationData }) => {

	const [cardNumber, setCardNumber] = useState("");
	const [amount, setAmount] = useState("");
	const [exp, setExp] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		nextStep();
	};

	const handleCardNumber = (e) => {
		let inputText = e.target.value;
		inputText = inputText.replace(/\D/g, "");
		const formattedCardNumber = inputText.replace(/(\d{4})/g, "$1 ");
		const value = formattedCardNumber.trim();
		setCardNumber(value);
		onCardNumberChange(value, 'billing_cardNumber');
	};

	const handleCVC = (event) => {
		const value = event.target.value.replace(/\D/g, "");
		setAmount(value);
		onCvcChange(value, 'billing_cvc');
	};

	const handleExpChange = (event) => {
		const value = event.target.value.replace(/\D/g, "");
		setExp(value);
		onExpChange(value, 'billing_exp');
	};

	// title="Sign Up"
	// 			subtitle="Payment"
	// 			text="Join an online community of educators and start educating."
	// 			60

	return (
		<>
			<form onSubmit={handleSubmit}>
					{/* <label className="form-label d-block mb-5 form--label">
						Subscriptions
					</label> */}
					<div className="row g-4">
						<div className="col-12 d-flex">
							<div className="payment-txt">
								<h5 className="subtitle">Total due today</h5>
								<span>This amount will be billed automatically on 02/06.</span>
							</div>
							<div>
								<button
									type="button"
									className="btn btn--secondary rounded h-45 font-bold"
								>
									$99.00
								</button>
							</div>
						</div>
						<div className="col-7">
							<div className="mt-4 text--center __h-45">
								<Input
									label="Card Number"
									placeholder=""
									value={cardNumber}
									onChange={handleCardNumber}
								/>
							</div>
							
						</div>
						<div className="col-3">
							<div className="mt-4 text--center __h-45">
								<Input
									label="EXP"
									placeholder=""
									value={exp}
									onChange={handleExpChange}
								/>
							</div>
						</div>
						<div className="col-2">
							<div className="mt-4 text--center __h-45">
								<Input
									label="CVC"
									placeholder=""
									value={amount}
									onChange={handleCVC}
								/>
							</div>
						</div>
					</div>
					<br />
					<br />
					<div className="text-white">
						Powered By <strong>Stripe</strong>
					</div>
					<br />
					<div className="mt-4 mt-sm-5">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
		</>
	);
};

export default CustomerSignUpStep4;
