import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import avatar from "../../../assets/img/avatar.png";
import {
	CommentsIcon,
	CommunityIcon,
	CommunityIcon2,
	CommunityIcon3,
	EmojiIcon,
	GalleryIcon,
	HeartFill,
	NextSlide,
	PrevSlide,
	RankBadge,
	ShareIcon,
	SignalIcon,
	VerifiedBadge,
	VideoSentIcon,
} from "../../../components/Icon";

import { Link } from "react-router-dom";
import img1 from "../../../assets/img/comunity/1.svg";
import img2 from "../../../assets/img/comunity/2.svg";
import img3 from "../../../assets/img/comunity/3.svg";
import img4 from "../../../assets/img/comunity/4.svg";
import img5 from "../../../assets/img/comunity/5.svg";
import trader1 from "../../../assets/img/trader1.png";

import SignalCard from "../../../components/SignalCard";
import { PinnedPost } from "../../profile/ProfileFeed";
import Banner from "./Banner";
import StorySection from "./StorySection";
import { APIBaseURL, CurrentCompany } from "../../../util/DomainHelper";
import DashboardSidebar from "./DashboardSidebar";
import NetworkingProvider from "../../../providers/NetworkingProvider";
import UserProvider from "../../../providers/UserProvider";
import data from "bootstrap/js/src/dom/data";

const ArticleArea = () => {

	const [following, setFollowing] = React.useState(false);
	const [communities, setCommunities] = useState();

	const [pinnedFeedPostItems, setPinnedFeedPostItems] = useState();
	const [feedPostItems, setFeedPostItems] = useState();
	const [paginationIndex, setPaginationIndex] = useState(0);

	const [commentContent, setCommentContent] = useState('')
	const [count, setCount] = useState(0);

	const ref = React.useRef(null);

	const goNext = () => {
		ref.current.swiper.slideNext();
	};

	const goPrev = () => {
		ref.current.swiper.slidePrev();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	const fetchFeedPosts = async (fetchedCommunities) => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `post-feed/items/${company._id}`;
		try {
			fetch(url, {
				method: 'POST',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({pageIndex: paginationIndex, perPage: 10})
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					console.log(data);
					if (data) {
						setCommunities(fetchedCommunities);
						setFeedPostItems(data);
					} else {
						console.log('Failed to fetch data');
						setCommunities(fetchedCommunities);
						setFeedPostItems([]);
					};
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	async function handleCommentSend() {
		if (commentContent) {
			const currentCurrent = await CurrentCompany();
			const currentUser = UserProvider.getCurrentUser()
			try {
				const payload = {
					userId: currentUser._id,
					content: commentContent,
					publicity: 'public'
				}
				const dataResponse = await NetworkingProvider.fetchData(`post-feed/create/${currentCurrent._id}`, 'POST', payload)
				console.log(dataResponse);
				const data = dataResponse.data;
				if (data) {
					setCount(count + 1)
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	useEffect(() => {

	},[]);

	return (
		<section className="article-area px-0">
			<div className="post-area bg-section" style={{ borderRadius: "36px" }}>
				<StorySection />

				<div className="styled-2-area p-0">
					<Banner />
				</div>
				<div className="styled-2-area">
					<div className="d-flex flex-wrap justify-content-between align-items-center mb-4 pb-1 gap-2 gap-sm-3">
						<Link to="#" className="media __media">
							<img src={UserProvider.getCurrentUser() ? ((UserProvider.getCurrentUser().photo) != '' ? UserProvider.getCurrentUser().photo : '/avatar/blankUser.jpg') : '/avatar/blankUser.jpg'} alt="" />
							<div className="media-body">
								<h6 className="text-16 font-semibold m-0 mb-1">
									{ UserProvider.getCurrentUser() ? (`${UserProvider.getCurrentUser().firstName} ${UserProvider.getCurrentUser().lastName}`) : null }
								</h6>
								<small>Top member</small>
							</div>
						</Link>
						<div className="d-flex flex-wrap gap-2 gap-sm-3">
							<select className="form-select form-control w-auto">
								<option>Trader</option>
								<option>User</option>
								<option>Customer</option>
								<option>Admin</option>
							</select>
							<select className="form-select form-control w-auto">
								<option>Post Type</option>
								<option>Pinned</option>
								<option>Sell</option>
							</select>
						</div>
					</div>
					{/* Post Textare */}
					<div className="textarea-wrapper">
						<form onSubmit={handleSubmit}>
							<textarea
								onChange={(e) => setCommentContent(e.target.value)}
								className="form-control"
								placeholder="What’s on your mind..."
							></textarea>
							<hr
								className="mx-3 my-0"
								style={{ borderColor: "#3B3B47" }}
							/>
							<div className="buttons-area d-flex  gap-sm-2">
								<button type="button" className="no-gutter px-2">
									<EmojiIcon />
								</button>
								<button type="button" className="no-gutter px-2">
									<GalleryIcon />
								</button>
								<button type="button" className="no-gutter px-2">
									<VideoSentIcon />
								</button>
								<button
									onClick={() => handleCommentSend()}
									type="submit"
									className="ms-auto cmn-btn text-16"
								>
									Post
								</button>
							</div>
						</form>
					</div>
					{/* Tabs */}
					<div className="tabs-area py-4">
						<div className="d-flex flex-wrap gap-3 gap-sm-4 justify-content-center">
							<button
								type="button"
								className={`no-gutter text-btn ${
									!following ? " active" : ""
								}`}
								onClick={() => setFollowing(false)}
							>
								For You
							</button>
							<button
								type="button"
								className={`no-gutter text-btn ${
									following ? " active" : ""
								}`}
								onClick={() => setFollowing(true)}
							>
								Following
							</button>
						</div>
					</div>
					<div>
						<PinnedPost loadMore count={count} />
					</div>
				</div>
			</div>
			<DashboardSidebar />
		</section>
	);
};

const community = [
	{
		title: "Mario",
		img: img1,
		view: "1.7k",
	},
	{
		title: "Pikachu",
		img: img2,
		view: "1.7k",
	},
	{
		title: "Smash mile",
		img: img3,
		view: "1.7k",
	},
	{
		title: "SuperTradingGang",
		img: img4,
		view: "1.7k",
	},
	{
		title: "TradeZilla Squad",
		img: img5,
		view: "1.7k",
	},
];
const traders = [
	{
		title: "HowardTradez",
		img: trader1,
	},
	{
		title: "HowardTradez",
		img: trader1,
	},
];

export default ArticleArea;
